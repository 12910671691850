import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"


const AboutTreatmentMaskPage = () => (
  <Layout sectionClasses={"t-about-treatmentmask"} dataNamespace={"about_lotion_moisturizer"} isCtaFloat={false} >
    <SEO title="About Treatment Mask" />
    <section className="t-about-treatmentmask__bg">
        <picture>
          <source media="(max-width:959px)" srcSet={"/images/about/treatmentmask/sp/fv.jpg"} />
          <img className="t-about-treatmentmask__bg__base" src={"/images/about/treatmentmask/pc/fv.jpg"} alt="" />
        </picture>
        <div className="t-about-treatmentmask__bg__front">
          <img className="t-about-treatmentmask__bg__title" src={"/images/about/treatmentmask/svg/treatment_mask_title.svg"} alt="" />
          <img className="t-about-treatmentmask__bg__mask" src={"/images/about/treatmentmask/svg/mask.svg"} alt="" />
          <img className="t-about-treatmentmask__bg__three_types_care" src={"/images/about/treatmentmask/svg/three_types_care.svg"} alt="" />
        </div>
    </section>

    <section className="t-about-treatmentmask__concept">
      <div className="t-about-treatmentmask__concept__left">
        <img className="t-about-treatmentmask__concept__left__img" src={"/images/about/treatmentmask/pc/01.jpg"} alt="" />
      </div>
      <div className="t-about-treatmentmask__concept__right">
        <h2>CONCEPT</h2>
        <h3>TREATMENT MASK</h3>
        <p className="t-about-treatmentmask__concept__right__txt">どんな肌質でもあなたのなりたい肌へ<br />
          大切な明日へつながる1日の最後のケア、<br />
          じっくりいたわってほしいから。
        </p>
      </div>
    </section>

    <section className="t-about-treatmentmask__theory">
      <div className="t-about-treatmentmask__theory__left">
        <h2>THEORY</h2>
        <p className="t-about-treatmentmask__theory__left__txt">HOTARU PERSONALIZEDの水分・油分・NMFの<br className="sp-hidden"/>バランス肌理論を応用したトリートメントマスク。<br />
          どんな肌にもぴたっと集中フィット。<br />
          みずみずしくなめらかな美容液が角層までしっかり浸透します。
        </p>
      </div>
      <div className="t-about-treatmentmask__theory__right">
        <img className="t-about-treatmentmask__theory__right__img" src={"/images/about/treatmentmask/pc/02.jpg"} alt="" />
      </div>
    </section>

    <div className="t-about-treatmentmask__three-types-care">
      <h2>THREE TYPES CARE</h2>
      <div className="t-about-treatmentmask__three-types-care__set">
        <div className="t-about-treatmentmask__three-types-care__block">
          <picture>
            <source media="(max-width:959px)" srcSet={"/images/about/treatmentmask/sp/white_alpha.png"} />
            <img className="t-about-treatmentmask__three-types-care__img" src={"/images/about/treatmentmask/pc/white_alpha.png"} alt="" />
          </picture>
          <p className="t-about-treatmentmask__three-types-care__txt">WHITE</p>
        </div>
        <div className="t-about-treatmentmask__three-types-care__block">
          <picture>
            <source media="(max-width:959px)" srcSet={"/images/about/treatmentmask/sp/shine_alpha.png"} />
            <img className="t-about-treatmentmask__three-types-care__img" src={"/images/about/treatmentmask/pc/shine_alpha.png"} alt="" />
          </picture>
          <p className="t-about-treatmentmask__three-types-care__txt">SHINE</p>
        </div>
        <div className="t-about-treatmentmask__three-types-care__block">
          <picture>
            <source media="(max-width:959px)" srcSet={"/images/about/treatmentmask/sp/clear_alpha.png"} />
            <img className="t-about-treatmentmask__three-types-care__img" src={"/images/about/treatmentmask/pc/clear_alpha.png"} alt="" />
          </picture>
          <p className="t-about-treatmentmask__three-types-care__txt">CLEAR</p>
        </div>
      </div>
    </div>

    <section className="t-about-treatmentmask__desc">
      <div className="t-about-treatmentmask__desc__block">
        <h2>HOTARU PERSONALIZED TREATMENT MASK</h2>
        <h3>ホタルパーソナライズド<br className="pc-hidden"/>トリートメントマスク</h3>
        <p className="t-about-treatmentmask__desc__block__set">＜1セット3包入り＞</p>
        <div className="t-about-treatmentmask__desc__block__label">
          <p className="left">４週間に<br />１回お届け(定期)</p>
          <p className="right">初回<p><span className="value">1,632円</span>(税込)</p></p>
        </div>
        <ul>
          <li><span>通常価格：3,267円(税込)</span></li>
          <li><span>2回目以降：2,940円(税込)</span></li>
          <li><span>内容量：1枚入り(35ml)x3包</span></li>
          <li><span>送料無料</span></li>
          <li><span>ご使用方法は<a href="https://sparty.my.site.com/spartyshopfaq/s/article/HOTARU-product-5">こちら</a></span></li>
        </ul>
        <p className="t-about-treatmentmask__desc__block__footnote">マイページに記載の次回出荷予定日の11日前（変更締切日）までに解約のお電話がない限り契約が続き、毎月自動で商品をお届けいたします。<br className={"sp-hidden"} />※土日祝その他休業日は前営業日。不良品や品違いの場合、交換・キャンセル可。 解約の電話番号：0570-666-886（月〜金　9:00〜18:00  ※ 祝日除く）</p>
      </div>
    </section>

    <section className="t-about-treatmentmask__offer">
      <div className="t-about-treatmentmask__offer__products">
        <div className="t-about-treatmentmask__offer__product-block">
          <img className="t-about-treatmentmask__offer__product-img" src={"/images/about/treatmentmask/pc/white.jpg"} />
          <h2>WHITE</h2>
          <p className="t-about-treatmentmask__offer__quasi-drugs">＜医薬部外品＞</p>
          <p className="t-about-treatmentmask__offer__product-catchphrase t-about-treatmentmask__offer__product-catchphrase--white">大人肌に明るいキメとツヤを</p>
          <p className="t-about-treatmentmask__offer__product-description">紫外線や乾燥によるシミ<span>※</span>くすみ肌の<br />ケアに、肌をワンランクトーンアップ</p>
          <p className="t-about-treatmentmask__offer__product-precaution"><span>※</span>メラニンの生成を抑え、しみ・そばかすを防ぐ</p>
          <p className="t-about-treatmentmask__offer__product-internal-capacity">内容量：1枚x3包</p>
          <div className="t-about-treatmentmask__offer__button">
            <a href={process.env.CART_SITE_URL + "/renewal/hotaru/treatment-mask/product-detail?setId=1"} className="button">購入する</a>
          </div>
        </div>
        <div className="t-about-treatmentmask__offer__product-block">
          <picture>
            <source media="(max-width:959px)" srcSet={"/images/about/treatmentmask/sp/shine.jpg"} />
            <img className="t-about-treatmentmask__offer__product-img" src={"/images/about/treatmentmask/pc/shine.jpg"} />
          </picture>
          <h2>SHINE</h2>
          <p className="t-about-treatmentmask__offer__quasi-drugs sp-hidden">　</p>
          <p className="t-about-treatmentmask__offer__product-catchphrase t-about-treatmentmask__offer__product-catchphrase--shine">キメの整ったなめらかな肌へ</p>
          <p className="t-about-treatmentmask__offer__product-description">あらゆる毛穴<span>※</span>の悩みにアプローチ、<br />すべすべ肌に</p>
          <p className="t-about-treatmentmask__offer__product-precaution"><span>※</span>キメの乱れと乾燥による毛穴の目立ち</p>
          <p className="t-about-treatmentmask__offer__product-internal-capacity">内容量：1枚x3包</p>
          <div className="t-about-treatmentmask__offer__button">
            <a href={process.env.CART_SITE_URL + "/renewal/hotaru/treatment-mask/product-detail?setId=2"} className="button">購入する</a>
          </div>
        </div>
        <div className="t-about-treatmentmask__offer__product-block">
          <picture>
            <source media="(max-width:959px)" srcSet={"/images/about/treatmentmask/sp/clear.jpg"} />
            <img className="t-about-treatmentmask__offer__product-img" src={"/images/about/treatmentmask/pc/clear.jpg"} />
          </picture>
          <h2>CLEAR</h2>
          <p className="t-about-treatmentmask__offer__quasi-drugs sp-hidden">　</p>
          <p className="t-about-treatmentmask__offer__product-catchphrase t-about-treatmentmask__offer__product-catchphrase--clear">ゆらがない健やかな肌へ</p>
          <p className="t-about-treatmentmask__offer__product-description">肌のバランスを整えて、<br />健康的でなめらかなツヤ肌へ</p>
          <p className="t-about-treatmentmask__offer__product-precaution sp-hidden">　</p>
          <p className="t-about-treatmentmask__offer__product-internal-capacity">内容量：1枚x3包</p>
          <div className="t-about-treatmentmask__offer__button">
            <a href={process.env.CART_SITE_URL + "/renewal/hotaru/treatment-mask/product-detail?setId=3"} className="button">購入する</a>
          </div>
        </div>
      </div>
      <div className="t-about-treatmentmask__offer__block">
        <picture>
          <source media="(max-width:959px)" srcSet={"/images/about/treatmentmask/sp/three_set.jpg"} />
          <img className="t-about-treatmentmask__offer__offer-img" src={"/images/about/treatmentmask/pc/three_set.jpg"} />
        </picture>
        <h2>WHITE/SHINE/CLEAR <br className="pc-hidden"/>の贅沢セット</h2>
        <p className="t-about-treatmentmask__offer__product-internal-capacity">内容量：3包(各種1枚ずつ)</p>
        <div className="t-about-treatmentmask__offer__button">
          <a href={process.env.CART_SITE_URL + "/renewal/hotaru/treatment-mask/product-detail?setId=4"} className="button">購入する</a>
        </div>
      </div>
    </section>
  </Layout>
)

export default AboutTreatmentMaskPage
